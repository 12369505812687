import React, { useState } from "react"

import Container from "layout/Container"
import Search from "../../Search/Search"
import MoleculeCoveredMessage from "../../Search/SearchFormulary/MoleculeCoveredMessage"

import useSitelMedensureProducts from "./hooks/useSitelMedensureProducts"

const HomeSearch = () => {
  const DATA = useSitelMedensureProducts()
  const [selectedItem, setSelectedItem] = useState({})
  const [coveredItems, setCoveredItems] = useState([])

  let dataMap = DATA.allAirtableallSitelMeProducts.nodes.map(
    (node) => node.data
  )

  const handleOnSelect = async (selectedItem) => {
    setSelectedItem(selectedItem)
    setCoveredItems(
      dataMap.filter((item) => {
        return item?.DrugName[0] === selectedItem.DrugName
      })
    )
  }

  return (
    <div id="search-medicines">
      <Container
        isCentered
        fullhd={10}
        desktop={10}
        tablet={12}
        mobile={12}
        showSideMargin={false}
        customClassName={"my-2 mx-2 mx-0-mobile"}
      >
        <div className="py-3 py-0-mobile py-0-tablet">
          <h3 className="mt-2 has-text-centered has-text-primary is-size-4-mobile">
            What medicines are covered by MedEnsure?
          </h3>

          <p className="mb-2">
            Search for your medicines with the branded or generic name. Only
            medicines you can find are covered in the program. Value brands will
            be dispensed when available.
          </p>
          <Search
            placeholder="Metformin"
            withIcon={true}
            onSelect={handleOnSelect}
            dataSource={{
              type: "graphql",
              data: dataMap,
              lookupKeys: ["Brand", "DrugName"],
            }}
          />
          <MoleculeCoveredMessage
            selectedMedicine={selectedItem}
            successIcon={DATA?.successIcon?.childImageSharp?.fixed}
            coveredItems={coveredItems}
          />
        </div>
      </Container>
    </div>
  )
}

export default HomeSearch
