export const getBenefits = () => {
  return [
    {
      program: "MedEnsure",
      icon: "medicines",
      title: "Full Year Regimen",
      description:
        "600 essential prescribed meds (value brands dispensed when available)",
    },
    {
      program: "MedEnsure",
      icon: "refills",
      title: "Proactive Refills",
      description: "Get proactive refills for your maintenance medicines",
    },
    {
      program: "MedEnsure",
      icon: "teleconsult",
      title: "Teleconsults",
      description: "Schedule a teleconsult with our general practitioners",
    },
    {
      program: "MedEnsure",
      icon: "delivery",
      title: "Free Delivery",
      description: "Have medicines delivered to your office",
    },
    {
      program: "FlexMed",
      icon: "medicines",
      title: "P10K Annual Allowance",
      description: "P2.5K per quarter for all ethical drugs",
    },
    {
      program: "FlexMed",
      icon: "branded",
      title: "Convenient and Flexible",
      description: "Access to urgent and branded meds",
    },
    {
      program: "FlexMed",
      icon: "reimburse",
      title: "Reimbursement",
      description: "Buy from any pharmacy and get reimbursed",
    },
    {
      program: "FlexMed",
      icon: "epharmacy",
      title: "Cashless ePharmacy",
      description: "Order online with no cash-out",
    },
  ]
}
