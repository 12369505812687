import { useStaticQuery, graphql } from "gatsby"

const ALL_SITEL_MEDENSURE_PRODUCTS = graphql`
  query SitelMeAllProducts {
    allAirtableallSitelMeProducts(
      filter: { data: { ShowOnWebsite: { eq: true } } }
    ) {
      nodes {
        data {
          VatexUnitPrice
          Vat
          PriceList
          Price
          UnitPrice
          DrugName
          Brand
          ProductTitle
          Molecule
          skuTags: SKUTags
          strength: Strength
          form: Form
        }
      }
    }
    successIcon: file(relativePath: { eq: "icons/me__check.png" }) {
      childImageSharp {
        fixed(width: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    formulary: file(relativePath: { eq: "icons/me__formulary.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const useSitelMedensureProducts = () => {
  const data = useStaticQuery(ALL_SITEL_MEDENSURE_PRODUCTS)

  return data
}

export default useSitelMedensureProducts
