import React from "react"
import classNames from "classnames"

import styles from "../utils/search.module.scss"

const CoveredMedicinesList = ({ coveredMedicines }) => (
  <div
    className={classNames(styles["searchResult__coveredMedicinesContainer"])}
  >
    <ul className="ml-2">
      {coveredMedicines.map((medicine) => (
        <li>{medicine.ProductTitle[0]}</li>
      ))}
    </ul>
  </div>
)

export default CoveredMedicinesList
