import React, { useEffect, useContext } from "react"

import { AppContext } from "../context/AppContext"

import Layout from "layout"
import HomeBanner from "components/StaticPages/Home/HomeBanner"

const IndexPage = () => {
  const { dispatch } = useContext(AppContext)

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Foundever"
    >
      <HomeBanner />
    </Layout>
  )
}

export default IndexPage
