import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import Container from "layout/Container"
import HomeCard from "components/StaticPages/Home/HomeCard"
import HomeSearch from "../../MedEnsure/Home/HomeSearch"
import useHomeImages from "../hooks/useHomeImages"

import styles from "../utils/staticPages.module.scss"

const HomeBanner = () => {
  const data = useHomeImages()
  const logo = data?.logo?.childImageSharp?.fixed

  return (
    <Container
      isCentered
      mobile={12}
      desktop={10}
      fullhd={8}
      showSideMargin={true}
    >
      <div className="is-flex my-2 is-hidden-mobile">
        <Img fixed={logo} alt="Foundever" className="mr-1" />
        <h2
          className={classNames(
            styles["homeBannerText"],
            "is-flex is-align-self-center has-text-centered"
          )}
        >
          Medicine Program
        </h2>
      </div>
      <div className="is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2 is-hidden-tablet is-hidden-desktop is-hidden-fullhd">
        <Img fixed={logo} alt="Foundever" />
        <h2
          className={classNames(
            styles["homeBannerText"],
            "has-text-centered pt-1"
          )}
        >
          Medicine Program
        </h2>
      </div>
      <HomeCard
        className="mb-4"
        program={{
          name: "Clinic | Telehealth",
          description: `Consult with a doctor face-to-face or online`,
        }}
        color="orange"
        size="medium"
        externalLinks={[
          {
            order: 0,
            label: "Check Into Clinic",
            to: "https://mdgr.cr/feintake",
          },
          {
            order: 1,
            label: "Book a Teleconsult",
            to: "https://maxi.care/MGtelecon",
          },
        ]}
      />
      <HomeCard
        program={{
          name: "MedEnsure",
          description: `Order your medicines and have them delivered to you`,
        }}
        color="orange"
        size="medium"
        link={{
          to: "/medensure/request/mechanics",
          label: "Request Medicines",
        }}
      />
      <HomeSearch />
      <HomeCard
        program={{
          name: "FlexMed",
          description: "Reimburse up to P2.5K of ethical drugs quarterly",
        }}
        color="orange"
        size="medium"
        link={{
          to: "/flexmed/reimburse/mechanics",
          label: "Upload Receipt",
        }}
        isLast
      />
    </Container>
  )
}

export default HomeBanner
