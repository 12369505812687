import React, { useEffect, Fragment } from "react"
import Img from "gatsby-image"
import classNames from "classnames"
import { navigate } from "gatsby"

import Button from "../../../Elements/Button"

import styles from "../../utils/staticPages.module.scss"
import { animate } from "services/animations"
import { getBenefits } from "../../utils/getBenefits"
import useHomeImages from "../../hooks/useHomeImages"

const HomeCard = ({
  className,
  program,
  color,
  link,
  externalLinks,
  isLast,
}) => {
  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-corporate",
      animateOn: ".animate-corporate-trigger",
    })
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-careers",
      animateOn: ".animate-careers-trigger",
    })
  }, [])

  let benefits = getBenefits()
  let programBenefits = benefits.filter(
    (benefits) => benefits.program === program.name
  )

  let images = useHomeImages()

  return (
    <Fragment>
      <div
        className={classNames({ "mb-2": isLast }, styles["card"], className)}
      >
        <div className="columns is-desktop">
          <div
            className={classNames(
              styles["card__imageWrapper"],
              styles[`card__${program.name.toLowerCase()}Icon`],
              "column m-1is-4-desktop"
            )}
          >
            <div className="p-1 m-1 m-0-mobile m-0-tablet">
              <h3 className="m-0"> {program?.name}</h3>
              <p className="subtitle mt-1 mb-2">{program?.description}</p>

              <div className="is-flex-tablet is-flex-column">
                {externalLinks?.map((externalLink, index) => {
                  return (
                    <a
                      href={externalLink?.to}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        color={color}
                        className={`is-fullwidth-mobile is-fullwidth-tablet ${
                          index === 0 && "mr-4 mb-1 is-outlined"
                        }`}
                        size="medium"
                      >
                        {externalLink?.label}
                      </Button>
                    </a>
                  )
                })}
              </div>

              {/* {externalLinks && externalLinks?.length > 0 && (
                <div className="is-flex is-justify-content-space-between">
                  <Button
                    color={color}
                    className="is-fullwidth-mobile is-fullwidth-tablet"
                    size="medium"
                  >
                    nigga
                  </Button>
                  <p>nigga</p>
                </div>
              )} */}

              {link && (
                <Button
                  onClick={() => navigate(link?.to)}
                  color={color}
                  className="is-fullwidth-mobile is-fullwidth-tablet"
                  size="medium"
                >
                  {link?.label}
                </Button>
              )}

              {/* <Button
                onClick={() => navigate(link?.to)}
                color={color}
                className="is-fullwidth is-hidden-tablet is-hidden-desktop is-hidden-fullhd"
                size="regular"
              >
                {link?.label}
              </Button> */}
            </div>
          </div>
          <div className="column py-2 is-flex is-flex-wrap-wrap has-text-centered is-hidden-mobile">
            {programBenefits.map((benefit, index) => (
              <div
                className={classNames(
                  `p-1 m-1 has-text-centered`,
                  styles["card__miniCard"],
                  styles[`card__miniCard__${program.name.toLowerCase()}Border`]
                )}
                key={index}
              >
                <p className="has-text-centered">
                  <Img
                    fixed={images[benefit.icon]?.childImageSharp.fixed}
                    alt="medensure logo"
                    className="has-text-centered"
                  />
                </p>
                <p>
                  <span className="has-text-weight-bold home-card-title">
                    {benefit?.title}
                  </span>
                  <p className="has-text-weight-normal home-card-description">
                    {benefit?.description}
                  </p>
                </p>
              </div>
            ))}
          </div>
          <div className="column is-hidden-tablet is-hidden-desktop is-hidden-fullhd">
            {programBenefits.map((benefit, index) => (
              <div
                className={classNames(
                  `p-1 m-0`,
                  styles["card__miniCard"],
                  styles[`card__miniCard__${program.name.toLowerCase()}Border`]
                )}
                key={index}
              >
                <div className="media">
                  <figure className="media-left">
                    <p className="image">
                      <Img
                        fixed={images[benefit.icon]?.childImageSharp.fixed}
                        alt="medensure logo"
                      />
                    </p>
                  </figure>
                  <div className={classNames(styles[""], "media-content")}>
                    <div className="content is-vcentered">
                      <p>
                        <span className="has-text-weight-bold home-card-title p-0">
                          {benefit?.title}
                        </span>
                        <p className="has-text-weight-normal home-card-description">
                          {benefit?.description}
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HomeCard
